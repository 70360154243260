import {FeatureApp, createFeatureAppIntegrator} from '@smart/feature-app/react';
// New
// import '@smart/react-components/index.css';
// Old
import type {MarketId} from '@smart/types';
import {fetchFeatureFlags} from '@smart/utils';
import '@smart/web-components/dist/foundation.css';
import React from 'react';
import {getClientConfig} from './utils/get-client-config';

const clientConfig = getClientConfig();

const shouldLoadLocalFeatureFlags =
  process.env.NODE_ENV === `development` &&
  window.location.hostname === `localhost`;

const integrator = () =>
  createFeatureAppIntegrator({
    App: ({endpointDirectory: {envName}}) => (
      <React.Fragment>
        <FeatureApp
          name="pdf-app"
          envName={envName}
          config={{usePDFViewer: clientConfig.usePDFViewer}}
        />
      </React.Fragment>
    ),
    id: `pdf-feature-app-integrator`,
    clientConfig,
  });

shouldLoadLocalFeatureFlags
  ? fetchFeatureFlags({
      projectId: `website`,
      marketId: clientConfig.locale.marketId as MarketId,
      envName: clientConfig.endpointDirectory.envName,
      preview: clientConfig.endpointDirectory.preview,
    })
      .then((flags) => {
        const ffContainer = document.createElement(`script`);
        ffContainer.id = `featureFlags`;
        ffContainer.type = `application/x-www-form-urlencoded`;
        ffContainer.innerHTML = `${encodeURIComponent(JSON.stringify(flags))}`;
        window.document.body.appendChild(ffContainer);
        integrator();
      })
      .catch((err) => {
        console.error(err);
      })
  : integrator();
